import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGuard implements CanActivate {

  constructor(private _msalService: MsalService,private _roleService: RoleService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return new Promise((resolve, reject) => { 
      this._roleService.isEmployee().then((response) => {
        let result = <any>response;
          if(result){
            resolve(true);
          }
          else {
            resolve(false);     
          }
        })
    })
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve, reject) => { 
        this._roleService.isEmployee().then((response) => {
          let result = <any>response;
            if(result){
              resolve(true);
            }
            else {
              resolve(false);     
            }
          })
      })

  }
}
