/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from "@azure/msal-browser";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    //Prod Envirotment
    clientId: "94a698bf-e5a4-4c57-bd74-4e7a13b16353", // This is the ONLY mandatory field that you need to supply.

    //Staging Environtment
    //clientId: '7483bbf3-0cee-4ff0-9943-1ee664df015b', // This is the ONLY mandatory field that you need to supply.

    authority:
      "https://login.microsoftonline.com/0ebdcb5f-b0a3-4463-8642-09eec2e9f76f", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {},
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  starhealthapi: {
    //Dev Environtment
    //endpoint: "https://localhost:44369/api/v1",
    //scopes: ["api://94a698bf-e5a4-4c57-bd74-4e7a13b16353/access_as_user"],

    //Prod Environtment
     endpoint: "https://starhealth.starenergy.id/api/v1",
     scopes: ["api://94a698bf-e5a4-4c57-bd74-4e7a13b16353/access_as_user"],

    //Dev Environtment and in SAGING
    // endpoint: "https://seg-starhealth-dev.azurewebsites.net/api/v1",
    // scopes: ["api://94a698bf-e5a4-4c57-bd74-4e7a13b16353/access_as_user"],
  },
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  armTenants: {
    endpoint: "https://management.azure.com/tenants",
    scopes: ["https://management.azure.com/user_impersonation"],
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};
