<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(assets/images/big/auth-bg.jpg) no-repeat center center;">
    <div>
        <div class="alert alert-primary">
            <span class="mr-1">Username : test</span> |
            <span class="ml-1">Password : test123</span>
        </div>
        <div class="auth-box">
            <div id="loginform" [ngClass]="{'d-none': recoverform}">
                <div class="logo">
                    <span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
                    <h5 class="font-medium m-b-20">Sign In to StarHealth</h5>
                </div>
                <div *ngIf="msg" class="alert alert-danger">{{ msg }}</div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-lg" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" #u1>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                </div>
                                <input type="password" class="form-control form-control-lg" placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" #p2>
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info" type="button" (click)="check(u1.value, p2.value)">Log
                                    In</button>
                                </div>
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-warning" type="button" (click)="login()">Log
                                    In With Azure AD</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>