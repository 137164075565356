import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    url = protectedResources.starhealthapi.endpoint;
    constructor(private http: HttpClient) { }

    getAllEmployeeWithPaging(search: string, order: string, page: number, max: number) {

        return this.http.get<any>(`${this.url}/employee/dependent/search?search=${search}&order=${order}&page=${page}&max=${max}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
  }

  getEmpPhoto(empid: string) {

    return this.http.get<any>(this.url+"/employee/empphoto?empId="+empid)
      .pipe(
        map((res: any) => {
          return res;
        }));
  }
}
