import { RouteInfo } from './sidebar.metadata';

export const ROUTESUser: RouteInfo[] = [
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/home',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  }
];

export const ROUTESDoctor: RouteInfo[] = [
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/home',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Apps',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: "/apps/medicalrecord",
    title: "Medical Record",
    icon: "mdi mdi-book-multiple",
    class: "",
    extralink: false,
    submenu: [],
  }];

export const ROUTESAdmin: RouteInfo[] = [
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/home',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Apps',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: "/apps/mcu",
    title: "Medical Check Up",
    icon: "mdi mdi-book-multiple",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/apps/glasses",
    title: "Glasses Reimbursement",
    icon: "mdi mdi-book-plus",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/apps/medicalrecord",
    title: "Medical Record",
    icon: "mdi mdi-medical-bag",
    class: "",
    extralink: false,
    submenu: [],
  }
];
