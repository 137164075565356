import { Component, AfterViewInit, OnInit, ChangeDetectorRef, AfterContentInit, Injectable } from '@angular/core';
import { ROUTESUser, ROUTESAdmin, ROUTESDoctor } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { protectedResources } from 'src/app/auth-config';
import { map } from 'rxjs/operators';
import { RoleModel } from '../model/role.model';
import { BehaviorSubject } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { Reportservice } from 'src/app/services/report.service';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, AfterViewInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  url = protectedResources.starhealthapi.endpoint;
  public data: string[] = [];
  public RoleModel: RoleModel[] = []
  
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(private _msalService: MsalService,
    private cdr: ChangeDetectorRef, private http: HttpClient, private _roleService: RoleService, private _reportService: Reportservice) { 
        this.getDataRole;
    }

  ngAfterContentChecked(): void {
  }

  async ngAfterViewInit(): Promise<void> {
    const allowedRoles = ['Task.Write', 'ADM'];
    //const userRoles = (this._msalService.instance.getActiveAccount()?.idTokenClaims as any).role;

    this.RoleModel = (await this._roleService.getRole() as any).Data;
    this.RoleModel.map(x => {     
      this.data.push(x.RoleId);
    });

    console.log( this.data.length > 0 ? "" : "Failed ( Sidebar.Component.ts : 64 )");
    
    this.sidebarnavItems = ROUTESUser.filter(sidebarnavItem => sidebarnavItem);
    
    const matchingRolesAdmin = this.data.filter(x => allowedRoles.includes(x));
    const matchingRolesDoctor = this.data.filter(x => ["DR"].includes(x));
    if (matchingRolesAdmin.length > 0) {     
      this.sidebarnavItems = ROUTESAdmin.filter(sidebarnavItem => sidebarnavItem);
    }
    else if (matchingRolesDoctor.length > 0){
      this.sidebarnavItems = ROUTESDoctor.filter(sidebarnavItem => sidebarnavItem);
    }

    this.cdr.detectChanges();
    
  }

  // End open close
  ngOnInit(): void {
  }

  // getRole
  async getDataRole() {
    let data = await this._roleService.getRole();
    return this.data;
  }

}

