import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protectedResources } from '../auth-config';
import Swal from 'sweetalert2';
import { Data } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MRecordService {
    url = protectedResources.starhealthapi.endpoint;
    constructor(private http: HttpClient) { }
    
    getAllMRecord(search: string, order: string, page: number, max: number) {

        return this.http.get<any>(`${this.url}/MRecord/getMedicalRecord?search=${search}&order=${order}&page=${page}&max=${max}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    addUpdateBloodDrug(data: FormData) {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        
      return this.http.post<any>(`${this.url}/MRecord/submitBloodDrugInfo`, data, { headers: headers })
        .pipe(
            map((res: any) => {
                return res;
            }));        
    }

    async getMRecordbyID(data: FormData) {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return await this.http.post<any>(`${this.url}/MRecord/getBloodDrugInfo`, data, { headers: headers }).toPromise();
    }

    async getMRTransactionById(Data: string, order: string, page: number, max: number) {

        return await this.http.get<any>(`${this.url}/MRecord/getMedRecTrans?EmpNo=${Data}&order=${order}&page=${page}&max=${max}`).toPromise();
    }

    async getFileDownload(data:FormData){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return await this.http.post(`${this.url}/MRecord/downloadSuppDoc`, data, { headers: headers, responseType: 'blob' }).toPromise();
    }

    async getAllDependentEmp(Data : string){
        return await this.http.get<any>(`${this.url}/MRecord/getDependantEmp?EmpNo=${Data}`).toPromise();

    }

    async getMRTransactionByTrans(Data: string){
        return await this.http.get<any>(`${this.url}/MRecord/getMedRecTransbyId?id=${Data}`).toPromise();
    }

    async getPrescriptionDownload(data:FormData){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return await this.http.post(`${this.url}/MRecord/downloadPrescription`, data, { headers: headers, responseType: 'blob' }).toPromise();
    }

    async getAgeEmpDepent(Data: string, DependantId:String){
        return await this.http.get<any>(`${this.url}/MRecord/getAge?id=${Data}&DependantId=${DependantId}`).toPromise();
    }

    async getDoctor(){
        return await this.http.get<any>(`${this.url}/MRecord/getDoctor`).toPromise();
    }

    async getDoctorbyId(Data: String){
        return await this.http.get<any>(`${this.url}/MRecord/getDoctorbyId?id=${Data}`).toPromise();
    }

    async getDoctorTransbyId(Data: String){
        return await this.http.get<any>(`${this.url}/MRecord/getDoctorTransbyId?id=${Data}`).toPromise();
    }

    async submitDataTransaction(data:FormData){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        
      return this.http.post<any>(`${this.url}/MRecord/addEditMRecordTransaction`, data, { headers: headers }).toPromise();  
             
    }

    async deleteTransaction(data:FormData){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
    
      return this.http.post<any>(`${this.url}/MRecord/deleteTransaction`, data, { headers: headers }).toPromise();  
             
    }

    async getExcelMedicalRecord(data:FormData){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return await this.http.post(`${this.url}/MRecord/medicalRecordExcel`, data, { headers: headers, responseType: 'blob' }).toPromise();
    }
}