import { Component, AfterViewInit, EventEmitter, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { GraphService } from 'src/app/services/graph.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};
  private readonly _destroying$ = new Subject<void>();
  public showSearch = false;
  name = '-';
  email = '-';
  jobTitle = '-';
  photo: any;

    constructor(
    private authService: MsalService,
    private _graphService: GraphService,
    private cdr: ChangeDetectorRef) {

  }
  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this._graphService.getUserProfile().subscribe(result => {
      this.name = result.displayName
      this.email = result.userPrincipalName
      this.jobTitle = result.jobTitle
    });
    this._graphService.getUserPhoto().subscribe(photo => this.photo = photo);
    //this.setAccount(this.authService.instance.getActiveAccount()?.idTokenClaims)
    this.cdr.detectChanges();
  }

  logout() {
    this.authService.logout();
  }

  setAccount(claims: any) {
    this.name = claims ? claims['name'] : 'No Name';
    this.email = claims ? claims['upn'] : 'No Email';
  }
}
