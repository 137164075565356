import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    url = protectedResources.starhealthapi.endpoint;
    constructor(private http: HttpClient) { }


    getMe() {
        return this.http.get<any>(`${this.url}/dashboard/me`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getLead() {

        return this.http.get<any>(`${this.url}/dashboard/islead`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getLastMcuMe() {

        return this.http.get<any>(`${this.url}/dashboard/lastmcu`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getLastMcuMember() {
        return this.http.get<any>(`${this.url}/dashboard/lastmembermcu`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getLastGlasses() {
        return this.http.get<any>(`${this.url}/dashboard/lastglases`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
  }

  
  
  
}
