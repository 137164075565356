import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { RoleService } from '../services/role.service';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  public result: boolean = false;
  constructor(private _roleService: RoleService, private msalBroadcastService: MsalBroadcastService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return new Promise((resolve, reject) => { 
      this._roleService.isAdmin().then((response) => {
        let result = <any>response;
          if(result){
            resolve(true);
          }
          else {
            resolve(false);     
          }
        })
    })
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve, reject) => { 
        this._roleService.isAdmin().then((response) => {
          let result = <any>response;
            if(result){
              resolve(true);
            }
            else {
              resolve(false);     
            }
          })
      })

  }

}

   

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   debugger;
    
  //   const Admin = this.isAdmin();
  //   if (Admin){
  //     return true;
  //   }

  //   return false;
  // }



