import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './guard/role.guard';
import { DoctorGuard } from './guard/doctor.guard';
import { EmployeeGuard } from './guard/employee.guard';


export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    
    children: [
      { path: '', canActivate: [MsalGuard], redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'home',
        canActivate: [MsalGuard, EmployeeGuard],
        loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'apps',
        canActivate: [MsalGuard],
        // data: {
        //   roles: [ 'Task.Write' ]
        // },
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)
      }
    ]
  }
];
