import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { protectedResources } from '../auth-config';
import { RoleModel } from '../shared/model/role.model';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    url = protectedResources.starhealthapi.endpoint;
    public data : string[]= [];
    public roleModel: RoleModel[] = []
    constructor(private http: HttpClient) { }

    async getRole(){
        return await this.http.get<any>(`${this.url}/role/user`).toPromise();
    }

    async isDoctor() {
        const Doctor = ['DR', 'ADM', 'Task.Write'] 
        this.roleModel = (await this.getRole() as any).Data;
        this.roleModel.map(x => {     
            this.data.push(x.RoleId);
          });
        if ( this.data.filter(x => Doctor.includes(x)).length > 0) 
        {  this.data = []; return true; }  
        else { return false; } 
    }

    async isEmployee() {
      this.data = []; return true;      
    }

    async isAdmin() {
        const Admin = ['ADM', 'Task.Write'] 
        this.roleModel = (await this.getRole() as any).Data;
        this.roleModel.map(x => {     
            this.data.push(x.RoleId);
          });
        if (  this.data.filter(x => Admin.includes(x)).length > 0) 
        { this.data = []; return true;} 
        else { return false; }
    }

    
}