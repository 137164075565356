import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class GlassesService {
    url = protectedResources.starhealthapi.endpoint;
    constructor(private http: HttpClient) { }

    getAllGlassesWithPaging(search: string, order: string, page: number, max: number) {

        return this.http.get<any>(`${this.url}/glasses/search?search=${search}&order=${order}&page=${page}&max=${max}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    downloadTemplateGlasses(): any {

        return this.http.get(`${this.url}/glasses/download`, { responseType: 'blob' })
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getLastGlasses(empid: string, status: string) {

        return this.http.get<any>(`${this.url}/glasses/get?empid=${empid}&status=${status}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getGlassesById(glassesid: string) {

        return this.http.get<any>(`${this.url}/glasses/getbyid/${glassesid}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    getGlassesMe() {

        return this.http.get<any>(`${this.url}/glasses/me`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    UploadGlasses(GFile: File) {
        let formData: FormData = new FormData();
        formData.append('file', GFile);


        return this.http.post<any>(`${this.url}/glasses/upload`, formData)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    insertGlasses(model:any) {

        return this.http.post<any>(`${this.url}/glasses/insert`, model)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    updateGlasses(model:any) {

        return this.http.post<any>(`${this.url}/glasses/update`, model)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    deleteGlasses(id:string) {

        return this.http.delete<any>(`${this.url}/glasses/delete/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }
}